<template>
  <v-dialog
    v-model="dialog"
    max-width="600"
  >
    <template #activator="{ on, attrs }">
      <div>
        <v-btn
          text
          small
          v-bind="attrs"
          v-on="on"
          :disabled="disabled"
          @click="presaveCurrentAnswer"
        >
          <v-icon left>
            mdi-cellphone
          </v-icon>
          {{ $t("live.question.open.upload.computer.sendFromSmartphone") }}
        </v-btn>

        <v-btn
          text
          small
          :disabled="disabled"
          @click="reloadAnswers"
        >
          <v-icon left>
            mdi-reload
          </v-icon>
          {{ $t("live.question.open.upload.computer.reload") }}
        </v-btn>
      </div>
    </template>
    <v-card>
      <v-card-title class="headline">
        {{ $t("live.question.open.upload.computer.sendFromSmartphone") }}
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col
            cols="6"
            v-html="
              $t('live.question.open.upload.computer.scanInsctructions', {
                phoneURL: phoneURL,
              })
            "
          />
          <v-col cols="6">
            <qrcode-vue
              v-if="
                (autoLoginUrl && showQrCode) ||
                  (autoLoginUrl && !currentUser.teacher)
              "
              :value="value"
              level="Q"
              size="250"
              class="mb-2 qrcode_warning"
              render-as="svg"
            />
            <v-alert
              dense
              text
              type="info"
              :icon="false"
            >
              {{ $t("live.question.open.upload.computer.qrCodeWarning") }}
            </v-alert>
            <v-btn
              v-if="currentUser.teacher && !showQrCode"
              @click="
                () => {
                  showQrCode = true;
                }
              "
              color="primary"
              class="mb-4 justify-center"
            >
              {{
                $t("live.question.open.upload.computer.qrCodeValidationButton")
              }}
            </v-btn>

            {{ $t("live.question.open.upload.computer.questionCode") }}
            {{ `${quiz?.id}-${quizzesAttemptSummary?.id}-${question?.id}` }}
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-actions>
        <v-spacer />
        <v-btn
          color="primary"
          text
          @click="dialog = false"
        >
          Ok
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import QrcodeVue from "qrcode.vue"
import api from "../../api"
import { mapActions, mapGetters } from "vuex"
import axios from "axios"

export default {
  name: "AnswerUploadQrCode",
  props: {
    question: {
      type: Object,
      required: true,
    },
    quiz: {
      type: Object,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    answerContent: {
      type: String,
    },
    quizzesAttemptSummary: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    dialog: false,
    autoLoginUrl: null,
    showQrCode: false,
  }),
  components: { QrcodeVue },
  computed: {
    ...mapGetters(["quizInstanceByQuizId", "currentUser"]),
    quizInstance() {
      return this.quizInstanceByQuizId(this.quiz?.id, this.currentUser?.id)
    },
    value() {
      const path = this.$router.match({
        name: "answerPictureUpload",
        params: {
          quizId: this.quiz?.id,
          quizzesAttemptSummaryId: this.quizzesAttemptSummary?.id,
          questionId: this.question?.id,
        },
      }).path
      const url = `${api.rootUrl()}/evalmee#${path}`
      return `${this.autoLoginUrl}&redirect=${url}`
    },
    phoneURL() {
      return `${api.rootUrl()}up`
    },
  },
  methods: {
    ...mapActions([
      "fetchQuizzesAttemptAnswers",
      "saveAnswersToLocalStorage",
      "getAnswersFromLocalStorage",
    ]),
    reloadAnswers() {
      // TODO: update this only fetch the question answers
      this.fetchQuizzesAttemptAnswers({
        quizzesAttemptSummaryId: this.quizzesAttemptSummary.id,
        questionId: this.question.id,
      }).then(this.syncAnswersWithLocalStorage)
    },
    syncAnswersWithLocalStorage() {
      // this.getAnswersFromLocalStorage(this.quizInstance).then(
      this.saveAnswersToLocalStorage({
        quizId: this.quiz.id,
        userId: this.currentUser.id,
      })
      // )
    },
    presaveCurrentAnswer() {
      if (this.answerContent === "") return
      return this.$emit("saveAnswer")
    },
  },
  mounted() {
    axios.post(this.currentUser.links.auto_login_url).then((response) => {
      this.autoLoginUrl = response.data.url
    })
  },
}
</script>

<style>
.qrcode_upload svg {
  box-shadow: 0 0 0 10px white !important;
}
</style>

<template>
  <div>
    <v-dialog
      v-model="dialog"
      max-width="90%"
    >
      <v-img
        :src="selectedImage"
        class="img-responsive"
        @click.stop="resetImage"
        :style="`transform: rotate(${rotateDeg});`"
      />

      <div class="toolbar">
        <v-btn
          @click.stop="rotation -= 90"
          icon
        >
          <v-icon>rotate_left</v-icon>
        </v-btn>

        <v-btn
          @click.stop="rotation += 90"
          icon
        >
          <v-icon>rotate_right</v-icon>
        </v-btn>
      </div>
    </v-dialog>

    <div class="d-flex gap-4 flex-wrap">
      <div
        v-for="(picture, index) in answer.pictures"
        :key="index"
      >
        <v-card
          :height="cardHeight"
          :width="cardHeight"
          flat
          outlined
        >
          <answer-upload-preview
            :picture="picture"
            :correction-mode="correctionMode"
            @delete="deletePicture(picture)"
            @select="selectImage(picture.link)"
          />
        </v-card>
      </div>
      <div v-if="!correctionMode">
        <dropzone-placeholder
          :title="$t('dropFile.title', { fileType: '' })"
          :subtitle="$t('dropFile.subtitle')"
          class="center"
          border-dots
          @click="upload"
          v-if="canUpload"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex"
import DropzonePlaceholder from "../shared/DropzonePlaceholder.vue"
import AnswerUploadPreview from "./answer_upload_preview.vue"

export default {
  name: "AnswerUploadsPreview",
  components: { AnswerUploadPreview, DropzonePlaceholder },
  props: {
    answer: { type: Object, required: true },
    correctionMode: { type: Boolean, required: true },
    canUpload: { type: Boolean, default: false },
    dense: { type: Boolean, default: false },
  },
  data: () => ({
    rotation: 0,
    dialog: false,
    selectedImage: null,
  }),
  computed: {
    rotateDeg() {
      return `${this.rotation}deg`
    },
    cardHeight() {
      return this.dense ? 120 : 160
    },
  },

  methods: {
    ...mapActions(["deletePictureFromAnswer"]),
    deletePicture(picture) {
      this.$vuetifyConfirmDialog.destroy()
      this.$vuetifyConfirmDialog
        .open(
          this.$t("live.question.open.upload.confirmModal.title"),
          "",
          this.$t("cancel"),
          this.$t("delete"),
          {
            cancelColor: "black",
            confirmColor: "primary",
          }
        )
        .then((validate) => {
          if (validate) {
            this.deletePictureFromAnswer(picture)
          }
        })
    },
    selectImage(link) {
      this.selectedImage = link
      this.dialog = true
    },
    resetImage() {
      this.selectedImage = null
      this.dialog = false
    },
    upload() {
      this.$emit("upload")
    },
  },
}
</script>

<style scoped>
.img-responsive {
  width: 100%;
}

.toolbar {
  width: auto;
  position: absolute;
  bottom: 46px;
  background: #cacaca96;
  border-radius: 100px;
  margin: 20px;
  backdrop-filter: blur(8px);
}
</style>
